import * as React from 'react';
import { withPrismicPreviewResolver } from 'gatsby-plugin-prismic-previews';
import { Layout } from '../components/Layout';

const PreviewPage = () => (
  <Layout>
    <h2>wait a minute</h2>
  </Layout>
);

export default withPrismicPreviewResolver(PreviewPage);
